import img_0 from "./images/kolaz/img_3.png"

const vojnik1152 = {
  "v": "5.6.8",
  "fr": 30,
  "ip": 43,
  "op": 192,
  "w": 1186,
  "h": 1627,
  "nm": "veliki kolaž lottie (2021-06-07) 1152px  Vojnik",
  "ddd": 0,
  "assets": [
    {
      "id": "image_0",
      "w": 274,
      "h": 1575,
      "u": "",
      "p": img_0,
      "e": 0
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 3,
      "nm": "NULL CONTROL",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 0, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [665.5, 1327.276, 0], "ix": 2 },
        "a": { "a": 0, "k": [60, 60, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "ip": 0,
      "op": 269,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 3,
      "nm": "NULL CONTROL",
      "parent": 1,
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 0, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": {
          "a": 1,
          "k": [
            {
              "i": { "x": 0.06, "y": 1 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 56,
              "s": [60, 60, 0],
              "to": [0, 12.333, 0],
              "ti": [0, -12.333, 0]
            },
            { "t": 201, "s": [60, 134, 0] }
          ],
          "ix": 2
        },
        "a": { "a": 0, "k": [60, 60, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "ip": 0,
      "op": 269,
      "st": 56,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 2,
      "nm": "4-C-Zagora_Proces-lovre-web_0000_vojnik 1.png",
      "cl": "png",
      "parent": 2,
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.575], "y": [0.992] },
              "o": { "x": [0.25], "y": [0.6] },
              "t": 44,
              "s": [-1.65]
            },
            { "t": 90, "s": [0] }
          ],
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": { "x": 0.588, "y": 0.686 },
              "o": { "x": 0.265, "y": 0.343 },
              "t": 26,
              "s": [437.72, 278.187, 0],
              "to": [-0.475, -2.315, 0],
              "ti": [0.378, 2.17, 0]
            },
            {
              "i": { "x": 0.563, "y": 0.778 },
              "o": { "x": 0.242, "y": 0.417 },
              "t": 44,
              "s": [436.405, 271.375, 0],
              "to": [-0.537, -3.082, 0],
              "ti": [0, 1.97, 0]
            },
            { "t": 90, "s": [435.5, 263.557, 0] }
          ],
          "ix": 2
        },
        "a": { "a": 0, "k": [137, 1575, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "ip": 0,
      "op": 213,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}
  

export default vojnik1152
