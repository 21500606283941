import img_0 from "./images/kolaz/img_0.png"
import img_1 from "./images/kolaz/img_1.png"
import img_2 from "./images/kolaz/img_2.png"
import img_3 from "./images/kolaz/img_4.png"
import img_4 from "./images/kolaz/img_5.png"

const planine1152 = {
    "v": "5.6.8",
    "fr": 30,
    "ip": 0,
    "op": 158,
    "w": 1186,
    "h": 785,
    "nm": "veliki kolaž lottie (2021-06-07) 1152px  kolaž",
    "ddd": 0,
    "assets": [
      {
        "id": "image_0",
        "w": 1150,
        "h": 398,
        "u": "",
        "p": img_0,
        "e": 0
      },
      {
        "id": "image_1",
        "w": 497,
        "h": 418,
        "u": "",
        "p": img_1,
        "e": 0
      },
      {
        "id": "image_2",
        "w": 165,
        "h": 403,
        "u": "",
        "p": img_2,
        "e": 0
      },
      {
        "id": "image_3",
        "w": 1150,
        "h": 205,
        "u": "",
        "p": img_3,
        "e": 0
      },
      {
        "id": "image_4",
        "w": 710,
        "h": 452,
        "u": "",
        "p": img_4,
        "e": 0
      }
    ],
    "layers": [
      {
        "ddd": 0,
        "ind": 1,
        "ty": 3,
        "nm": "NULL CONTROL",
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 0, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": { "a": 0, "k": [665.5, 452.276, 0], "ix": 2 },
          "a": { "a": 0, "k": [60, 60, 0], "ix": 1 },
          "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 213,
        "st": -56,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 2,
        "ty": 3,
        "nm": "NULL CONTROL",
        "parent": 1,
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 0, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": {
            "a": 1,
            "k": [
              {
                "i": { "x": 0.06, "y": 1 },
                "o": { "x": 0.167, "y": 0.167 },
                "t": 0,
                "s": [60, -14, 0],
                "to": [0, 12.333, 0],
                "ti": [0, -12.333, 0]
              },
              { "t": 145, "s": [60, 60, 0] }
            ],
            "ix": 2
          },
          "a": { "a": 0, "k": [60, 60, 0], "ix": 1 },
          "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 213,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 3,
        "ty": 2,
        "nm": "Zagora-minimalno---_0000_Prednji-plan.png",
        "cl": "png",
        "parent": 2,
        "refId": "image_0",
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 100, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": {
            "a": 1,
            "k": [
              {
                "i": { "x": 0.14, "y": 1 },
                "o": { "x": 0.167, "y": 0.167 },
                "t": 0,
                "s": [-12.5, 118.224, 0],
                "to": [0, 11.333, 0],
                "ti": [0, -11.333, 0]
              },
              { "t": 154, "s": [-12.5, 186.224, 0] }
            ],
            "ix": 2
          },
          "a": { "a": 0, "k": [575, 199, 0], "ix": 1 },
          "s": { "a": 0, "k": [104, 104, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 213,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 4,
        "ty": 2,
        "nm": "samo otok.png",
        "cl": "png",
        "parent": 2,
        "refId": "image_1",
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 100, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": {
            "a": 1,
            "k": [
              {
                "i": { "x": 0.14, "y": 1 },
                "o": { "x": 0.167, "y": 0.167 },
                "t": 78,
                "s": [-256.5, 170.948, 0],
                "to": [0, 16.692, 0],
                "ti": [0, -6.859, 0]
              },
              { "t": 154, "s": [-256.5, -28.188, 0] }
            ],
            "ix": 2
          },
          "a": { "a": 0, "k": [248.5, 209, 0], "ix": 1 },
          "s": { "a": 0, "k": [104, 104, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 213,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 5,
        "ty": 2,
        "nm": "4-C-Zagora_Proces-lovre-web_0000s_0000_Layer-16 1.png",
        "cl": "png",
        "parent": 2,
        "refId": "image_2",
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 100, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": {
            "a": 1,
            "k": [
              {
                "i": { "x": 0.08, "y": 1 },
                "o": { "x": 0.167, "y": 0.167 },
                "t": 66,
                "s": [23.5, 129.224, 0],
                "to": [0.667, -39.667, 0],
                "ti": [-0.667, 39.667, 0]
              },
              { "t": 139, "s": [27.5, -108.776, 0] }
            ],
            "ix": 2
          },
          "a": { "a": 0, "k": [82.5, 201.5, 0], "ix": 1 },
          "s": { "a": 0, "k": [76, 76, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 213,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 6,
        "ty": 3,
        "nm": "NULL CONTROL",
        "parent": 2,
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 0, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": { "a": 0, "k": [420.5, 206.276, 0], "ix": 2 },
          "a": { "a": 0, "k": [60, 60, 0], "ix": 1 },
          "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 213,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 7,
        "ty": 2,
        "nm": "Zagora-minimalno---_0001_Srednje-brdo.png",
        "cl": "png",
        "parent": 2,
        "refId": "image_3",
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 100, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": {
            "a": 1,
            "k": [
              {
                "i": { "x": 0.14, "y": 1 },
                "o": { "x": 0.167, "y": 0.167 },
                "t": 0,
                "s": [-10.5, 171.224, 0],
                "to": [0, -28.167, 0],
                "ti": [0, 28.167, 0]
              },
              { "t": 154, "s": [-10.5, 2.224, 0] }
            ],
            "ix": 2
          },
          "a": { "a": 0, "k": [575, 102.5, 0], "ix": 1 },
          "s": { "a": 0, "k": [104, 104, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 213,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 8,
        "ty": 2,
        "nm": "Zagora-minimalno---_0001_Srednje-brdo.png",
        "cl": "png",
        "parent": 7,
        "refId": "image_3",
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 100, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": { "a": 0, "k": [575, 120.5, 0], "ix": 2 },
          "a": { "a": 0, "k": [575, 102.5, 0], "ix": 1 },
          "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 213,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 9,
        "ty": 2,
        "nm": "Zagora-minimalno---_0002_Daleko-brdo.png",
        "cl": "png",
        "parent": 2,
        "refId": "image_4",
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 100, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": {
            "a": 1,
            "k": [
              {
                "i": { "x": 0.14, "y": 1 },
                "o": { "x": 0.167, "y": 0 },
                "t": 0,
                "s": [-320.5, 129.224, 0],
                "to": [0, -40.333, 0],
                "ti": [0, 40.333, 0]
              },
              { "t": 154, "s": [-320.5, -112.776, 0] }
            ],
            "ix": 2
          },
          "a": { "a": 0, "k": [355, 226, 0], "ix": 1 },
          "s": { "a": 0, "k": [82, 82, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 213,
        "st": 0,
        "bm": 0
      }
    ],
    "markers": []
  }
  

export default planine1152
