import img_0 from "./images/kolaz/img_0.png"
import img_1 from "./images/kolaz/img_1.png"
import img_2 from "./images/kolaz/img_2.png"
import img_3 from "./images/kolaz/img_3.png"
import img_4 from "./images/kolaz/img_4.png"
import img_5 from "./images/kolaz/img_5.png"

const kolaz750 = {
    "v": "5.6.8",
    "fr": 30,
    "ip": 0,
    "op": 153,
    "w": 750,
    "h": 1489,
    "nm": "veliki kolaž lottie 750px",
    "ddd": 0,
    "assets": [
      {
        "id": "image_0",
        "w": 1150,
        "h": 398,
        "u": "",
        "p": img_0,
        "e": 0
      },
      {
        "id": "image_1",
        "w": 497,
        "h": 418,
        "u": "",
        "p": img_1,
        "e": 0
      },
      {
        "id": "image_2",
        "w": 165,
        "h": 403,
        "u": "",
        "p": img_2,
        "e": 0
      },
      {
        "id": "image_3",
        "w": 274,
        "h": 1575,
        "u": "",
        "p": img_3,
        "e": 0
      },
      {
        "id": "image_4",
        "w": 1150,
        "h": 205,
        "u": "",
        "p": img_4,
        "e": 0
      },
      {
        "id": "image_5",
        "w": 710,
        "h": 452,
        "u": "",
        "p": img_5,
        "e": 0
      }
    ],
    "layers": [
      {
        "ddd": 0,
        "ind": 1,
        "ty": 3,
        "nm": "NULL CONTROL",
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 0, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": { "a": 0, "k": [427.5, 1250.276, 0], "ix": 2 },
          "a": { "a": 0, "k": [60, 60, 0], "ix": 1 },
          "s": { "a": 0, "k": [66, 66, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 153,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 2,
        "ty": 3,
        "nm": "NULL CONTROL",
        "parent": 1,
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 0, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": {
            "a": 1,
            "k": [
              {
                "i": { "x": 0.06, "y": 1 },
                "o": { "x": 0.167, "y": 0.167 },
                "t": 0,
                "s": [60, -14, 0],
                "to": [0, 12.333, 0],
                "ti": [0, -12.333, 0]
              },
              { "t": 129, "s": [60, 60, 0] }
            ],
            "ix": 2
          },
          "a": { "a": 0, "k": [60, 60, 0], "ix": 1 },
          "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 153,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 3,
        "ty": 2,
        "nm": "Zagora-minimalno---_0000_Prednji-plan.png",
        "cl": "png",
        "parent": 2,
        "refId": "image_0",
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 100, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": {
            "a": 1,
            "k": [
              {
                "i": { "x": 0.14, "y": 1 },
                "o": { "x": 0.167, "y": 0.167 },
                "t": 0,
                "s": [-12.5, 118.224, 0],
                "to": [0, 11.333, 0],
                "ti": [0, -11.333, 0]
              },
              { "t": 138, "s": [-12.5, 186.224, 0] }
            ],
            "ix": 2
          },
          "a": { "a": 0, "k": [575, 199, 0], "ix": 1 },
          "s": { "a": 0, "k": [104, 104, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 153,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 4,
        "ty": 2,
        "nm": "samo otok.png",
        "cl": "png",
        "parent": 2,
        "refId": "image_1",
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 100, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": {
            "a": 1,
            "k": [
              {
                "i": { "x": 0.14, "y": 1 },
                "o": { "x": 0.167, "y": 0.167 },
                "t": 89,
                "s": [-256.5, 170.948, 0],
                "to": [0, 16.692, 0],
                "ti": [0, -6.859, 0]
              },
              { "t": 149, "s": [-256.5, -28.188, 0] }
            ],
            "ix": 2
          },
          "a": { "a": 0, "k": [248.5, 209, 0], "ix": 1 },
          "s": { "a": 0, "k": [104, 104, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 11,
        "op": 164,
        "st": 11,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 5,
        "ty": 3,
        "nm": "NULL CONTROL",
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 0, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": { "a": 0, "k": [444.031, 1146.174, 0], "ix": 2 },
          "a": { "a": 0, "k": [60, 60, 0], "ix": 1 },
          "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 153,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 6,
        "ty": 2,
        "nm": "4-C-Zagora_Proces-lovre-web_0000s_0000_Layer-16 1.png",
        "cl": "png",
        "parent": 5,
        "refId": "image_2",
        "sr": 1,
        "ks": {
          "o": {
            "a": 1,
            "k": [
              {
                "i": { "x": [0.833], "y": [0.833] },
                "o": { "x": [0.167], "y": [0.167] },
                "t": 65,
                "s": [0]
              },
              { "t": 66, "s": [100] }
            ],
            "ix": 11
          },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": {
            "a": 1,
            "k": [
              {
                "i": { "x": 0.08, "y": 1 },
                "o": { "x": 0.167, "y": 0.167 },
                "t": 66,
                "s": [57.379, 237.6, 0],
                "to": [0.44, -29.81, 0],
                "ti": [-0.44, 29.81, 0]
              },
              { "t": 123, "s": [60.019, 58.74, 0] }
            ],
            "ix": 2
          },
          "a": { "a": 0, "k": [82.5, 201.5, 0], "ix": 1 },
          "s": { "a": 0, "k": [50.16, 50.16, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 35,
        "op": 153,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 7,
        "ty": 3,
        "nm": "NULL CONTROL",
        "parent": 2,
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 0, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": { "a": 0, "k": [420.5, 206.276, 0], "ix": 2 },
          "a": { "a": 0, "k": [60, 60, 0], "ix": 1 },
          "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 153,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 8,
        "ty": 3,
        "nm": "NULL CONTROL",
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 0, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": { "a": 0, "k": [640.63, 1383.414, 0], "ix": 2 },
          "a": { "a": 0, "k": [60, 60, 0], "ix": 1 },
          "s": { "a": 0, "k": [73, 73, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 153,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 9,
        "ty": 2,
        "nm": "4-C-Zagora_Proces-lovre-web_0000_vojnik 1.png",
        "cl": "png",
        "parent": 8,
        "refId": "image_3",
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 100, "ix": 11 },
          "r": {
            "a": 1,
            "k": [
              {
                "i": { "x": [0.12], "y": [1] },
                "o": { "x": [0.167], "y": [0.167] },
                "t": 0,
                "s": [-4]
              },
              { "t": 90, "s": [0] }
            ],
            "ix": 10
          },
          "p": {
            "a": 1,
            "k": [
              {
                "i": { "x": 0.14, "y": 1 },
                "o": { "x": 0.167, "y": 0.167 },
                "t": 0,
                "s": [60, 81.12, 0],
                "to": [0, -3.52, 0],
                "ti": [0, 3.52, 0]
              },
              { "t": 138, "s": [60, 60, 0] }
            ],
            "ix": 2
          },
          "a": { "a": 0, "k": [137, 1575, 0], "ix": 1 },
          "s": { "a": 0, "k": [66, 66, 100], "ix": 6 }
        },
        "ao": 0,
        "hasMask": true,
        "masksProperties": [
          {
            "inv": false,
            "mode": "a",
            "pt": {
              "a": 0,
              "k": {
                "i": [
                  [0, 0],
                  [0, 0],
                  [0, 0],
                  [0, 0]
                ],
                "o": [
                  [0, 0],
                  [0, 0],
                  [0, 0],
                  [0, 0]
                ],
                "v": [
                  [610.084, -91.433],
                  [-184.914, -91.433],
                  [-184.914, 1413.324],
                  [610.084, 1413.324]
                ],
                "c": true
              },
              "ix": 1
            },
            "o": { "a": 0, "k": 100, "ix": 3 },
            "x": { "a": 0, "k": 0, "ix": 4 },
            "nm": "Mask 1"
          }
        ],
        "ip": 0,
        "op": 153,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 10,
        "ty": 2,
        "nm": "Zagora-minimalno---_0001_Srednje-brdo.png",
        "cl": "png",
        "parent": 2,
        "refId": "image_4",
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 100, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": {
            "a": 1,
            "k": [
              {
                "i": { "x": 0.14, "y": 1 },
                "o": { "x": 0.167, "y": 0.167 },
                "t": 0,
                "s": [-10.5, 71.224, 0],
                "to": [0, -11.5, 0],
                "ti": [0, 11.5, 0]
              },
              { "t": 138, "s": [-10.5, 2.224, 0] }
            ],
            "ix": 2
          },
          "a": { "a": 0, "k": [575, 102.5, 0], "ix": 1 },
          "s": { "a": 0, "k": [104, 104, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 153,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 11,
        "ty": 2,
        "nm": "Zagora-minimalno---_0002_Daleko-brdo.png",
        "cl": "png",
        "parent": 2,
        "refId": "image_5",
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 100, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": {
            "a": 1,
            "k": [
              {
                "i": { "x": 0.14, "y": 1 },
                "o": { "x": 0.167, "y": 0 },
                "t": 0,
                "s": [-320.5, 129.224, 0],
                "to": [0, -40.333, 0],
                "ti": [0, 40.333, 0]
              },
              { "t": 138, "s": [-320.5, -112.776, 0] }
            ],
            "ix": 2
          },
          "a": { "a": 0, "k": [355, 226, 0], "ix": 1 },
          "s": { "a": 0, "k": [82, 82, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 153,
        "st": 0,
        "bm": 0
      }
    ],
    "markers": []
  }
  

export default kolaz750
