import img_0 from "./images/kolaz/img_0.png"
import img_1 from "./images/kolaz/img_1.png"
import img_2 from "./images/kolaz/img_2.png"
import img_3 from "./images/kolaz/img_4.png"
import img_4 from "./images/kolaz/img_5.png"

const kolaz550 = {
    "v": "5.6.8",
    "fr": 30,
    "ip": 0,
    "op": 153,
    "w": 550,
    "h": 400,
    "nm": "veliki kolaž lottie 550px",
    "ddd": 0,
    "assets": [
      {
        "id": "image_0",
        "w": 1150,
        "h": 398,
        "u": "",
        "p": img_0,
        "e": 0
      },
      {
        "id": "image_1",
        "w": 497,
        "h": 418,
        "u": "",
        "p": img_1,
        "e": 0
      },
      {
        "id": "image_2",
        "w": 165,
        "h": 403,
        "u": "",
        "p": img_2,
        "e": 0
      },
      {
        "id": "image_3",
        "w": 1150,
        "h": 205,
        "u": "",
        "p": img_3,
        "e": 0
      },
      {
        "id": "image_4",
        "w": 710,
        "h": 452,
        "u": "",
        "p": img_4,
        "e": 0
      }
    ],
    "layers": [
      {
        "ddd": 0,
        "ind": 1,
        "ty": 3,
        "nm": "NULL CONTROL",
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 0, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": { "a": 0, "k": [301.5, 228.329, 0], "ix": 2 },
          "a": { "a": 0, "k": [60, 60, 0], "ix": 1 },
          "s": { "a": 0, "k": [71, 71, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 153,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 2,
        "ty": 3,
        "nm": "NULL CONTROL",
        "parent": 1,
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 0, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": { "a": 0, "k": [60, 50.447, 0], "ix": 2 },
          "a": { "a": 0, "k": [60, 60, 0], "ix": 1 },
          "s": { "a": 0, "k": [66, 66, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 153,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 3,
        "ty": 3,
        "nm": "NULL CONTROL",
        "parent": 1,
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 0, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": {
            "a": 1,
            "k": [
              {
                "i": { "x": 0.06, "y": 1 },
                "o": { "x": 0.167, "y": 0.167 },
                "t": 0,
                "s": [60, 1.607, 0],
                "to": [0, 8.14, 0],
                "ti": [0, -8.14, 0]
              },
              { "t": 129, "s": [60, 50.447, 0] }
            ],
            "ix": 2
          },
          "a": { "a": 0, "k": [60, 60, 0], "ix": 1 },
          "s": { "a": 0, "k": [66, 66, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 153,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 4,
        "ty": 2,
        "nm": "Zagora-minimalno---_0000_Prednji-plan.png",
        "cl": "png",
        "parent": 1,
        "refId": "image_0",
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 100, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": {
            "a": 1,
            "k": [
              {
                "i": { "x": 0.14, "y": 1 },
                "o": { "x": 0.167, "y": 0.167 },
                "t": 0,
                "s": [12.15, 88.875, 0],
                "to": [0, 7.48, 0],
                "ti": [0, -7.48, 0]
              },
              { "t": 138, "s": [12.15, 133.755, 0] }
            ],
            "ix": 2
          },
          "a": { "a": 0, "k": [575, 199, 0], "ix": 1 },
          "s": { "a": 0, "k": [68.64, 68.64, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 153,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 5,
        "ty": 2,
        "nm": "samo otok.png",
        "cl": "png",
        "parent": 1,
        "refId": "image_1",
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 100, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": {
            "a": 1,
            "k": [
              {
                "i": { "x": 0.14, "y": 1 },
                "o": { "x": 0.167, "y": 0.167 },
                "t": 78,
                "s": [-148.89, 123.673, 0],
                "to": [0, 11.017, 0],
                "ti": [0, -4.527, 0]
              },
              { "t": 138, "s": [-148.89, -7.757, 0] }
            ],
            "ix": 2
          },
          "a": { "a": 0, "k": [248.5, 209, 0], "ix": 1 },
          "s": { "a": 0, "k": [68.64, 68.64, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 153,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 6,
        "ty": 3,
        "nm": "NULL CONTROL",
        "parent": 1,
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 0, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": { "a": 0, "k": [247.531, -40.655, 0], "ix": 2 },
          "a": { "a": 0, "k": [60, 60, 0], "ix": 1 },
          "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 153,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 7,
        "ty": 2,
        "nm": "4-C-Zagora_Proces-lovre-web_0000s_0000_Layer-16 1.png",
        "cl": "png",
        "parent": 1,
        "refId": "image_2",
        "sr": 1,
        "ks": {
          "o": {
            "a": 1,
            "k": [
              {
                "i": { "x": [0.833], "y": [0.833] },
                "o": { "x": [0.167], "y": [0.167] },
                "t": 65,
                "s": [0]
              },
              { "t": 66, "s": [100] }
            ],
            "ix": 11
          },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": {
            "a": 1,
            "k": [
              {
                "i": { "x": 0.08, "y": 1 },
                "o": { "x": 0.167, "y": 0.167 },
                "t": 66,
                "s": [244.91, 136.945, 0],
                "to": [0.44, -29.81, 0],
                "ti": [-0.44, 29.81, 0]
              },
              { "t": 123, "s": [247.55, -41.915, 0] }
            ],
            "ix": 2
          },
          "a": { "a": 0, "k": [82.5, 201.5, 0], "ix": 1 },
          "s": { "a": 0, "k": [50.16, 50.16, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 35,
        "op": 153,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 8,
        "ty": 3,
        "nm": "NULL CONTROL",
        "parent": 1,
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 0, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": { "a": 0, "k": [297.93, 146.989, 0], "ix": 2 },
          "a": { "a": 0, "k": [60, 60, 0], "ix": 1 },
          "s": { "a": 0, "k": [66, 66, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 153,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 10,
        "ty": 2,
        "nm": "Zagora-minimalno---_0001_Srednje-brdo.png",
        "cl": "png",
        "parent": 1,
        "refId": "image_3",
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 100, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": {
            "a": 1,
            "k": [
              {
                "i": { "x": 0.14, "y": 1 },
                "o": { "x": 0.167, "y": 0.167 },
                "t": 0,
                "s": [13.47, 57.855, 0],
                "to": [0, -7.59, 0],
                "ti": [0, 7.59, 0]
              },
              { "t": 138, "s": [13.47, 12.315, 0] }
            ],
            "ix": 2
          },
          "a": { "a": 0, "k": [575, 102.5, 0], "ix": 1 },
          "s": { "a": 0, "k": [68.64, 68.64, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 153,
        "st": 0,
        "bm": 0
      },
      {
        "ddd": 0,
        "ind": 11,
        "ty": 2,
        "nm": "Zagora-minimalno---_0002_Daleko-brdo.png",
        "cl": "png",
        "parent": 1,
        "refId": "image_4",
        "sr": 1,
        "ks": {
          "o": { "a": 0, "k": 100, "ix": 11 },
          "r": { "a": 0, "k": 0, "ix": 10 },
          "p": {
            "a": 1,
            "k": [
              {
                "i": { "x": 0.14, "y": 1 },
                "o": { "x": 0.167, "y": 0 },
                "t": 0,
                "s": [-191.13, 96.135, 0],
                "to": [0, -26.62, 0],
                "ti": [0, 26.62, 0]
              },
              { "t": 138, "s": [-191.13, -63.585, 0] }
            ],
            "ix": 2
          },
          "a": { "a": 0, "k": [355, 226, 0], "ix": 1 },
          "s": { "a": 0, "k": [54.12, 54.12, 100], "ix": 6 }
        },
        "ao": 0,
        "ip": 0,
        "op": 153,
        "st": 0,
        "bm": 0
      }
    ],
    "markers": []
  }
  
  

export default kolaz550
